.career-component {
    .container-1 {
        width: 100%;
        display: grid;
        grid-gap: var(--dl-space-space-oneandhalfunits);
        grid-template-columns: 1fr 1fr;
        background-color: var(--dl-color-gray-white);
        padding: 50px 50px 75px 50px;
        margin-top: 50px;
    }
    .learn-more {
        margin: 10px;
        margin-top: 30px;
        h2 {
            margin-bottom: 20px;
        }
    }
    .container-3 {
        margin: 20px;
        h2 {
            margin-bottom: 10px;
        }
        p {
            font-size: 18px;
            font-family: "Poppins", serif;
            font-weight: 400;
        }
       
    }
    @media(max-width: 767px) {
        .container-1 {

            grid-template-columns: 1fr;
        }
    }
}