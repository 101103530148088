
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;

  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  .onclick-menu-content li {
    list-style: none;
  }
  .onclick-menu-content {
    /* use opacity to fake immediate toggle */
     display: none;
     opacity: 0;
     visibility: hidden;
     transition: visibility 0.5s;
     font-size: 12px;
     left: 15px;
     position: relative;
 }
 a:hover {
  color: tomato;
}
 .onclick-menu:hover .onclick-menu-content {
  /*  opacity is 1 in opened state (see below) */
  opacity: 1;
  visibility: visible;
  display: inherit;
  /* don't let pointer-events affect descendant elements */
  pointer-events: auto;
}
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #ddd;}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {display: block;}
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {background-color: #147487;}