.about-component {



    .about-image {
        width: 100%;
        height: 100%;
        vertical-align: top;

    }

    .service-cards {
        flex: 0 0 auto;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .home-container03 {
        width: 100%;
        display: grid;
        grid-gap: var(--dl-space-space-oneandhalfunits);
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media(max-width: 767px) {
        .home-container03 {
            grid-template-columns: 1fr;
        }
    }

    .hexagon {
        justify-content: center;
        display: flex;
    }

    .number-text {

        justify-content: center;
        display: flex;
        font-size: 20px;
        text-align: center;
        font-family: Inter Tight;
        font-weight: 700;
        line-height: 150%;

    }

    .choose-us-text {
        display: flex;
        justify-content: left;
        text-align: center;
        font-size: 20px;
        text-align: center;
        font-family: Inter Tight;
        font-weight: 300;
        line-height: 150%;
    }

    .service-cards1 {
        gap: var(--dl-space-space-threeunits);
        width: 100%;
        display: flex;
        max-width: var(--dl-size-size-maxwidth);
        align-items: center;
        padding-top: var(--dl-space-space-fourunits);
        padding-left: var(--dl-space-space-threeunits);
        padding-right: var(--dl-space-space-threeunits);
        flex-direction: row;
        padding-bottom: var(--dl-space-space-fourunits);
        justify-content: flex-start;
    }
}