.contact-component {
    .contact-us-container {
        width: 100%;
        display: grid;
        grid-gap: var(--dl-space-space-oneandhalfunits);
        grid-template-columns: 1fr 1fr;
        background-color: var(--dl-color-gray-white);
        padding: 50px 50px 75px 50px;
        margin-top: 50px;

        .address-container {
            margin-top: 10px;
        }
    }

    .address-list {
        list-style-type: none;

        .address-list-item {
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            font-size: inherit;
            padding-bottom: calc(10px / 2);
        }

        .list-item-text {
            align-self: center;
            padding-left: 5px;
            font-family: "Poppins", Sans-serif;
            font-size: 15px;
            font-weight: 400;
        }
    }

    @media(max-width: 767px) {
        .contact-us-container {

            grid-template-columns: 1fr;
        }
    }
}