.lets-connect {
    background: rgba(41, 44, 48, 1);
    width: 100%;
    padding: 8rem 0;
    text-align: center;
    overflow: hidden;
    clear: both;
    margin-top: 0;
    color: white;
    margin-top: 20px;
    .container {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: 50px;
    }

    @media (max-width: 1299px) {
        p {
            font-size: 20px;
            line-height: 32px;
        }
    }
    @media (max-width: 1050px) {
        .connect-form {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    @media (max-width: 1050px) {
        .connect-form {
            padding: 28px 20px 29px;
        }
    }
    .connect-form {
        border-radius: 4rem;
        -webkit-border-radius: 4rem;
        box-shadow: none;
        padding: 52px 50px 54px;
        text-align: left;
        background-color: rgba(255, 255, 255, 1);
       
    }
   
     p {
        font-size: 22px;
        line-height: 36px;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 0;
        margin-top: 0;
    }
    @media (min-width: 992px) {
        .container, .container-lg, .container-md, .container-sm {
            max-width: 960px;
        }
    }
    @media (min-width: 768px) {
        .container, .container-md, .container-sm {
            max-width: 720px;
        }
    }
    @media (min-width: 576px) {
        .container, .container-sm {
            max-width: 540px;
        }
    }
    h2 {
        line-height: 36px;
        color: rgba(180, 180, 180, 1);
        margin-bottom: 4.1rem;
        margin-top: 0;
    }
    @media (max-width: 1460px) {
        h2 {
            font-size: 40px;
        }
    }
    .row {
        width: 100%;
        display: grid;
        grid-gap: 300px;
        grid-template-columns: 400px 400px;
        
    }
    .css-1dq6meb-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
        width: -webkit-fill-available;
    }
    @media(max-width: 767px) {
        .row {
            grid-template-columns: 1fr;
        }
    }
}